export const defaultMacroNutrients = {
  calories: {
    name: 'Calories',
    raw: 0,
    amount: 0,
    unit: 'kcal',
  },
  proteins: {
    name: 'Proteins',
    raw: 0,
    amount: 0,
    unit: 'g',
  },
  carbs: {
    name: 'Carbs',
    raw: 0,
    amount: 0,
    unit: 'g',
  },
  fats: {
    name: 'Fats',
    raw: 0,
    amount: 0,
    unit: 'g',
  },
}
export const calculateMacroNutrients = selectedFoods$ => {
  const macroNutrients = JSON.parse(JSON.stringify(defaultMacroNutrients))
  selectedFoods$.subscribe(
    selectedFood => {
      selectedFood.amount = selectedFood.amount || 0
      macroNutrients.calories.raw += selectedFood.food.calories * selectedFood.amount/100
      macroNutrients.proteins.raw += selectedFood.food.proteins * selectedFood.amount/100
      macroNutrients.carbs.raw += selectedFood.food.carbohydrates * selectedFood.amount/100
      macroNutrients.fats.raw += selectedFood.food.fat * selectedFood.amount/100
    },
    err => console.error(err),
    () => {
      macroNutrients.calories = formatMacroNutrient(macroNutrients.calories, ['kcal'])
      macroNutrients.proteins = formatMacroNutrient(macroNutrients.proteins, ['g', 'g', 'g'])
      macroNutrients.carbs = formatMacroNutrient(macroNutrients.carbs, ['g', 'g', 'g'])
      macroNutrients.fats = formatMacroNutrient(macroNutrients.fats, ['g', 'g', 'g'])
    },
  )

  return macroNutrients
}
const formatMacroNutrient = (macroNutrient, units) =>
  Object.assign(macroNutrient, unitize(macroNutrient.raw, units))

export const unitize = (amount, units) => {
  let unit = units.pop()
  while (amount >= 1) {
    if (units.length === 0) {
      return {
        amount: parseFloat(parseFloat(amount).toFixed(2)),
        unit,
      }
    }
    amount /= 1
    unit = units.pop()
  }
  return {
    amount: parseFloat(parseFloat(amount).toFixed(2)),
    unit,
  }
}

const getMicroNutrientsDefaults = nutrients$ => {
  const microNutrients = {
     }
  nutrients$.subscribe(
    nutrient => {
      if (!microNutrients[nutrient.name]) {
        microNutrients[nutrient.name] = {
          rda: nutrient.rda,
          raw: 0,
          amount: 0,
          unit: 'units',
          percentage: 0,
        }
      }
    },
    err => console.log(err),
    () => {},
  )
  return microNutrients
}
const percentage = (amount, total) => parseInt((amount * 100) / total, 10)

export const calculateMicroNutrients = (selectedFoods$, nutrients$) => {
  const microNutrients = getMicroNutrientsDefaults(nutrients$)

  selectedFoods$.subscribe(selectedFood => {
    nutrients$.subscribe(
      
      nutrient => {
        const foodNutrients = selectedFood.food.nutrients[nutrient.name]
        microNutrients[nutrient.name].raw += (foodNutrients || 0) * selectedFood.amount/100
                },
      err => console.error(err),
      () => {
        nutrients$.subscribe(nutrient => {
          microNutrients[nutrient.name].percentage = percentage(
            microNutrients[nutrient.name].raw,
            nutrient.rda,
          )
          microNutrients[nutrient.name].raw = parseFloat(parseFloat(microNutrients[nutrient.name].raw).toFixed(2))
          const r = unitize(microNutrients[nutrient.name].raw, ['units', 'units', 'units'])
          microNutrients[nutrient.name].amount = r.amount
          microNutrients[nutrient.name].unit = r.unit
          microNutrients.Calcium.unit = "mg"
          microNutrients.Iron.unit = "mg"
          microNutrients.Potassium.unit = "mg"
          microNutrients.Magnesium.unit = "mg"
          microNutrients.Phosphorus.unit = "mg"
          microNutrients.Sodium.unit = "mg"
          microNutrients.Zinc.unit = "mg"
          microNutrients.Copper.unit = "mg"
          microNutrients.Manganese.unit = "mg"
          microNutrients.Selenium.unit = "mg"
          microNutrients["Vitamin A"].unit = "μg_RAE"
          microNutrients["Vitamin B1"].unit = "mg"
          microNutrients["Vitamin B2"].unit = "mg"
          microNutrients["Vitamin B3"].unit = "mg"
          microNutrients["Vitamin B5"].unit = "mg"
          microNutrients["Vitamin B6"].unit = "mg"
          microNutrients["Vitamin B7"].unit = "N/V"
          microNutrients["Vitamin B9"].unit = "μg"
          microNutrients["Vitamin C"].unit = "mg"
          microNutrients["Vitamin E"].unit = "mg"
          microNutrients["Vitamin K"].unit = "μg"
          microNutrients.Choline.unit = "N/V"
          microNutrients.Tryptophan.unit = "g"
          microNutrients.Threonine.unit = "g"
          microNutrients.Isoleucine.unit = "g"
          microNutrients.Leucine.unit = "g"
          microNutrients.Lysine.unit = "g"
          microNutrients.Methionine.unit = "g"
          microNutrients.Phenylalanine.unit = "g"
          microNutrients.Valine.unit = "g"
          microNutrients.Histidine.unit = "g"
          microNutrients["Linoleic acid"].unit = "N/V"
          microNutrients["α-Linolenic acid"].unit = "N/V"
        })
      },
    )
  })

  return microNutrients
}
